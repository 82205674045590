.primary-menu {
	@apply text-base font-kanit flex flex-col mb-0 lg:flex-row lg:flex-wrap lg:items-center lg:space-x-4 xl:space-x-[2.522704339051463vw] 2xl:space-x-[5.208333333333333vw];

	> li {
		@apply relative flex flex-row flex-wrap lg:items-center;
		> a {
			@apply text-primary font-semibold uppercase flex flex-row flex-nowrap items-center flex-grow lg:justify-center py-2 lg:py-sm relative;

			@apply lg:before:w-0 lg:before:h-[3px] lg:before:bg-primary lg:before:rounded-full lg:before:transition-all lg:before:duration-150 lg:before:ease-in-out lg:before:absolute lg:before:inset-x-0 lg:before:bottom-[3px] lg:before:mx-auto;
		}

		&:hover,
		&.active,
		&.current,
		&.current-page-parent,
		&.current_page_parent,
		&.current_page_ancestor {
			> a {
				@apply lg:before:w-11;
			}
		}
	}


	.dropdown {
		@apply w-full pl-4 hidden lg:bg-gradient-to-r lg:from-primary lg:to-secondary lg:w-auto lg:absolute lg:top-full lg:left-span-6 lg:px-5 lg:py-md lg:block lg:opacity-0 lg:invisible lg:transition-visible lg:z-[1000] rounded-2xl lg:-translate-x-span-6 lg:min-w-[200px] lg:space-y-[8px] lg:mt-sm;

		@apply before:lg:w-full lg:before:h-sm lg:before:absolute lg:before:inset-x-0 lg:before:-top-sm;

		&.open {
			@apply block;
		}

		> li {
			> a {
				@apply text-primary py-2 lg:py-0 lg:text-white flex flex-row flex-nowrap items-center relative;

				@apply lg:before:w-0 lg:before:h-[2px] lg:before:bg-white lg:before:rounded-full lg:before:transition-all lg:before:duration-150 lg:before:ease-in-out lg:before:absolute lg:before:left-0 lg:before:-bottom-[2px];
			}
			&:hover,
			&.active,
			&.current,
			&.current-page-parent,
			&.current_page_parent,
			&.current_page_ancestor {
				> a {
					@apply lg:before:w-11;
				}
			}
		}

		&-toggle {
			@apply flex flex-row items-center flex-nowrap;
			&-button {
				@apply w-[40px] min-h-[40px] text-xl leading-[0] h-full flex flex-col justify-center items-center flex-nowrap outline-none focus:outline-none lg:hidden;
				.close { @apply hidden; }
				.open { @apply block; }
				&.open {
					.close { @apply block; }
					.open { @apply hidden; }
				}
			}
		}
		.dropdown {
			@apply lg:top-0 lg:left-full lg:translate-x-0 lg:mt-0 lg:before:hidden;
		}
	}

	.has-dropdown {
		&:hover {
			> .dropdown {
				@apply lg:opacity-100 lg:visible;
			}
		}
	}

}