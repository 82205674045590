/*! purgecss start ignore */
h1, .h1 {
	@apply text-h1 font-bold;
}
h2, .h2 {
	@apply text-h2 font-bold;
}
h3, .h3 {
	@apply text-h3 font-bold;
}
h4, .h4 {
	@apply text-h4 font-bold;
}
h5, .h5 {
	@apply text-h5 font-bold;
}
h6, .h6 {
	@apply text-h6 font-bold;
}

a {
	@apply text-primary no-underline;
}
p, ul, ol {
	@apply mb-4;
}
ul, ol {
	@apply pl-8;
}
ul:not(.list-unstyled) {
	@apply list-none pl-0;
	li {
		@apply pl-5;
		&::before {
			content: '';
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.74 13.1'%3E%3Cpath d='M8.34,5.71,1.79.25A1.1,1.1,0,0,0,.25.39,1.1,1.1,0,0,0,.39,1.93L5.94,6.55.39,11.17a1.1,1.1,0,0,0-.14,1.54,1.09,1.09,0,0,0,1.54.14h0L8.34,7.39a1.09,1.09,0,0,0,.14-1.54A.93.93,0,0,0,8.34,5.71Z' fill='%2346969F' /%3E%3C/svg%3E");
			@apply w-[6px] h-[9px] inline-block bg-no-repeat bg-cover bg-center -ml-5 mr-md flex-[0_0_9px];
		}
	}
}
.prose-white ul:not(.list-unstyled) li::before {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.74 13.1'%3E%3Cpath d='M8.34,5.71,1.79.25A1.1,1.1,0,0,0,.25.39,1.1,1.1,0,0,0,.39,1.93L5.94,6.55.39,11.17a1.1,1.1,0,0,0-.14,1.54,1.09,1.09,0,0,0,1.54.14h0L8.34,7.39a1.09,1.09,0,0,0,.14-1.54A.93.93,0,0,0,8.34,5.71Z' fill='%23FFFFFF' /%3E%3C/svg%3E");
}
ol {
	@apply list-decimal;
}
.list-unstyled {
	@apply list-none pl-0;
}
:last-child {
	@apply mb-0;
}
/*! purgecss end ignore */