.btn {
	@apply text-lg leading-normal font-bold inline-block text-center py-[.78125rem] px-7 focus:outline-none transition-colors ease-linear duration-150;

	&.primary {
		@apply text-white bg-primary hover:bg-primary-darker;
	}
	&.secondary {
		@apply text-white bg-secondary hover:bg-secondary-darker;
	}

}