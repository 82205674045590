.form-input,
.form-multiselect,
.form-select,
.form-textarea {
	@apply text-regent-gray font-archivo font-medium text-[17px] w-full border-athens focus:border-regent-gray bg-athens focus:ring-regent-gray focus:ring-2 focus:ring-opacity-30 p-5 rounded-md block;

	&::placeholder {
		@apply text-current opacity-100;
	}
}

.form-textarea {
	@apply min-h-[180px];
}
.form-checkbox,
.form-radio {
	@apply text-regent-gray border-2 border-regent-gray focus:ring-regent-gray w-5 h-5;
}
.form-checkbox {
	@apply rounded-sm;
	+ span {
		@apply text-current;
	}
}

.form-file {
	@apply cursor-pointer;
	&::-webkit-file-upload-button,
	&::file-selector-button {
		@apply text-white -ml-5 mr-6 my-[-20px] px-5 py-[19px] appearance-none border-0 bg-athens cursor-pointer;
	}
}

.form-label {
	@apply text-athens inline-block mb-sm;
}

.form-error {
	@apply text-red-500;
}

.has-error {
	.form-label {
		@apply text-red-500;
	}
	.form-input,
	.form-multiselect,
	.form-select,
	.form-textarea,
	.form-file {
		@apply text-red-500 border-red-300 focus:border-red-500 bg-red-200 focus:ring-red-300;
	}
	.form-file {
		&::-webkit-file-upload-button,
		&::file-selector-button {
			@apply bg-red-500;
		}
	}
	.form-checkbox,
	.form-radio {
		@apply text-red-500 border-red-500 focus:ring-red-300;
		+ span {
			@apply text-red-500;
		}
	}
}