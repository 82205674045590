@import "tiny-slider/dist/tiny-slider.css";
@import "@fancyapps/ui/dist/fancybox.css";

@import "tailwindcss/base";

@import "./components/prose.css";

@import "tailwindcss/components";

/* @import "./components/container.css"; */
@import "./components/buttons.css";
@import "./components/forms.css";
@import "./components/primary-menu.css";

@import "tailwindcss/utilities";